import styled from 'styled-components'
import { Image } from '@netpurpose/np-ui'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  min-height: 100vh;
  width: 100%;

  @media only screen and (max-device-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
  }
`

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
`

export const ContentContainer = styled.div<{ $wide: boolean }>`
  width: 100%;
  max-width: ${({ $wide }) => ($wide ? '50rem' : '25rem')};
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LoginBox = styled.div<{ $increaseMinHeight?: boolean }>`
  width: 100%;
  display: flex;
  border-radius: ${({ theme }) => theme.border.radiusLarge};
  background-color: ${({ theme }) => theme.colors.bgSecondary};
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadow.regular};
  min-height: ${({ $increaseMinHeight }) => ($increaseMinHeight ? '35rem' : '30rem')};
`

export const LoginBoxShadow = styled.div`
  margin-top: 1.875rem;
  width: 100%;
  height: 140px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    ${({ theme }) => theme.colors.typeSecondary} 0%,
    rgba(6, 9, 111, 0) 100%
  );
  opacity: 0.1;

  @media only screen and (max-device-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const LoginImage = styled(Image)`
  width: 24rem;
  @media only screen and (max-device-width: ${({ theme }) => theme.breakpoints.mobile}) {
    display: none;
  }
`

export const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const FormBox = styled.div<{ $withFooter?: boolean; $withBackButton?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $withFooter, $withBackButton }) => {
    if ($withBackButton) {
      return '0 2.5rem 1rem'
    }
    if ($withFooter) {
      return '2.5rem 2.5rem 1rem'
    }
    return '2.5rem'
  }};
  height: 100%;
`
