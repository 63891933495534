import Image from 'next/image'
import { FC } from 'react'
import { NextLink } from '@netpurpose/np-ui'
import { BaseLayout } from '#components/common/Layout'
import { config } from '#services/config'
import {
  Container,
  ContentContainer,
  FormBox,
  FormContainer,
  HeadingContainer,
  LoginBox,
  LoginBoxShadow,
  LoginImage,
} from './AuthPage.style'

type Props = {
  title: string
  showImage?: boolean
  children: React.ReactNode
  FormFooter?: React.ReactNode | null
  withBackButton?: boolean
  SSOSignInEnabled?: boolean
}

const AuthPage: FC<Props> = ({
  children,
  title,
  showImage = true,
  FormFooter,
  withBackButton,
  SSOSignInEnabled = false,
}) => {
  return (
    <BaseLayout title={title}>
      <Container>
        <HeadingContainer>
          <NextLink href={config.routes.login}>
            <Image
              width={209}
              height={32}
              src="/images/netpurpose_logo_black.svg"
              alt="Net Purpose logo"
            />
          </NextLink>
        </HeadingContainer>
        <ContentContainer $wide={showImage}>
          <LoginBox $increaseMinHeight={showImage && SSOSignInEnabled}>
            {showImage && <LoginImage name="trees.png" alt="coniferous forest birds eye view" />}
            <FormContainer>
              <FormBox $withFooter={!!FormFooter} $withBackButton={!!withBackButton}>
                {children}
              </FormBox>
              {FormFooter}
            </FormContainer>
          </LoginBox>
          <LoginBoxShadow />
        </ContentContainer>
      </Container>
    </BaseLayout>
  )
}

export default AuthPage
