import { ComponentProps } from 'react'
import styled from 'styled-components'
import Heading from '../../components/Heading'
import { NextLink as Link } from '../../components/NextLink'

type HeadingProps = ComponentProps<typeof Heading>

const HeadingLevelOne = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level={1} />
const HeadingLevelTwo = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level={2} />
const HeadingLevelThree = (props: Omit<HeadingProps, 'level'>) => <Heading {...props} level={3} />

export const HeadingOne = styled(HeadingLevelOne)<{ withSubheading?: boolean }>`
  font-family: Blacker, Gilroy, serif;
  color: ${({ theme }) => theme.colors.black};
  line-height: 2.5rem;
  text-align: ${({ withSubheading }) => (withSubheading ? 'center' : 'auto')};
  margin-bottom: ${({ withSubheading }) => (withSubheading ? 0 : '1.5rem')};
`

export const HeadingText = styled(HeadingLevelTwo)<{ withSubheading?: boolean }>`
  font-family: Blacker, Gilroy, serif;
  color: ${({ theme }) => theme.colors.black};
  text-align: ${({ withSubheading }) => (withSubheading ? 'center' : 'auto')};
  margin-bottom: ${({ withSubheading }) => (withSubheading ? 0 : '1.5rem')};
`

export const SubHeadingText = styled(HeadingLevelThree)`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.typePrimary};
  text-align: center;
  margin-bottom: 2rem;
`

export const LargeBodyText = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  color: ${({ theme }) => theme.colors.typePrimary};
`

export const BodyText = styled.p`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.typePrimary};
`

export const AuthLinkText = styled.span<{ $black?: boolean }>`
  cursor: pointer;
  font-weight: 700;
  color: ${({ theme, $black }) => ($black ? theme.colors.typePrimary : theme.colors.actionLink)};
  font-size: 0.875rem;
  line-height: 1rem;
`

export const StyledLink = styled(Link)<{ $black?: boolean }>`
  font-weight: bold;
  color: ${({ theme, $black }) => ($black ? theme.colors.typePrimary : theme.colors.actionLink)};
  font-size: 0.875rem;
  line-height: 1rem;
`

export const Text = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
`

export const EmailText = styled.strong`
  font-weight: 700;
  word-break: break-word;
`

export const FormSectionContainer = styled.div<{ $center?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem 0 0;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
  && > :first-child {
    margin-top: 0;
  }
`

export const LinksContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${({ theme }) => theme.colors.typePrimary};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
`

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const SignUpActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.5rem;
  text-align: center;

  button {
    margin-bottom: 1.5rem;
  }
`

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  > div:last-child:not(:only-child) {
    margin-left: 1rem;
  }
`

export const SingleActionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const FormikInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const InputLabel = styled.label`
  && {
    font-size: 0.875rem;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.architecture6};
    margin-bottom: 0.5rem;
  }
`

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  > label {
    margin-right: 0.5rem;
  }

  > p {
    margin-bottom: 0;
  }
`

export const NewsletterCheckboxContainer = styled(CheckboxContainer)`
  align-items: flex-start;
`
