import { FC } from 'react'
import { Icon } from '../Icon'
import { Container, IconContainer, WarningText } from './AuthFormError.style'

interface Props {
  error?: Error
}

export const AuthFormError: FC<Props> = ({ error }) => {
  if (!error) {
    return null
  }
  return (
    <Container data-testid="AuthFormError">
      <IconContainer>
        <Icon icon="Warning" alt="warning" color="semanticNegative" />
      </IconContainer>
      <WarningText>{error.message || error.toString()}</WarningText>
    </Container>
  )
}
